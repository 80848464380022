import { h } from 'preact';
import style from './style.css';

const Disabled = () => (
    <noscript>
        <div class={style.disabled}>
            <h1>Hey, looks like you have javascript disabled. Unfortunately I havent retrofitted my website to handle a Javascript apocalypse. In the meantime, grab a coffee, or you know, enable Javascript. Thanks!</h1>
        </div>
    </noscript>
);

export default Disabled;
