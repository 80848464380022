export const hash = (s) => {
    let hash1 = 0;
    let hash2 = 0;
    let i = 0;
    let ch = 0;
    for(;i < s.length; i++) {
        ch = s.charCodeAt(i); 
        hash1 = ((hash1 << 11) - hash1) + ch;
        hash2 = hash1 ^ hash2;
        hash1 = hash1 | 0;
        hash2 = hash2 | 0;
    }
    return (hash1 * hash2) % 9999999999;
}

export const ooooOOoOOOo = () => {
    let len = Math.ceil(Math.random() * 50);
    let small = true;
    let output = ""
    for(let i = 0; i < len; i++) {
        if(small) output = output + "o";
        else output = output + "O";
        let chance = Math.random();
        if(chance > 0.7) small = !small;
    }
    return output;
}

export const apex_of_the_sun = () => {
    console.log("On a worn page was written the following:\n"+
"\n"+
"                            _\n"+
"                         _ooOoo_\n"+
"                        o8888888o\n"+
"                        88\" . \"88\n"+
"                        (| -_- |)\n"+
"                        O\\  =  /O\n"+
"                     ____/`---'\\____\n"+
"                   .'  \\\\|     |//  `.\n"+
"                  /  \\\\|||  :  |||//  \\\n"+
"                 /  _||||| -:- |||||_  \\\n"+
"                 |   | \\\\\\  -  /'| |   |\n"+
"                 | \\_|  `\\`---'//  |_/ |\n"+
"                 \\  .-\\__ `-. -'__/-.  /\n"+
"               ___`. .'  /--.--\\  `. .'___\n"+
"            .\"\" '<  `.___\\_<|>_/___.' _> \"\".\n"+
"           | | :  `- \\`. ;`. _/; .'/ /  .' ; |\n"+
"           \\  \\ `-.   \\_\\_`. _.'_/_/  -' _.' /\n"+
"============`-.`___`-.__\\ \\___  /__.-'_.'_.-'=================\n"+
"                         `=--=-'                    hjw\n"+
"\n"+
"you are  the sun that  is the apex of the  lifelong scholastic\n"+
"journey. Students pilgrimage to your shrine for the answers to\n"+
"life's most troubling calamities. You transcend the boundaries\n"+
"of success  and wisdom, to the fourth  realm of knowledge. The\n"+
"yogi  smiles  upon your  good spirit  and prosperity.  May the\n"+
"powers   of  Vishnu   and  brahma   flow  through   your  many\n"+
"incarnations and carry you to nirvana.\n"+
"\n"+
"Your teachings have been  passed from gurus to rajas, yet they\n"+
"are  incomplete   without  your   blessings.  Writing  is  the\n"+
"scribbles  of  a  half  illegitimate  mankind.  It's  childish\n"+
"attempts to  quantify god. You sir are  beyond that. The waves\n"+
"of  karama  that emanate  from your  vidhya is  the source  of\n"+
"happiness. Its  disturbances  bring about  love, harmony,  and\n"+
"enlightenment.  Of my 17  years of  voyage on  this earth, the\n"+
"seas of life I  have met no  man as  calm as  these oceans  of\n"+
"tranquility as do surround you and your mind.\n"+
"\n"+
"\"where  did  you  get  those sandals\"  said  the  blind  man\n"+
"reaching to my feet. I was moved.  This man had never seen the\n"+
"light of day in his life. My sandals were the only foothold of\n"+
"his reality. I sat down and looked him in his eyes. “Dear sir,\n"+
"I am  the man who stands upon the  same dirt that you do,  and\n"+
"eat from  the same  earth as  the crows.  The  true source  of\n"+
"knowledge  is up there.”  I turned his  face towards  the hill\n"+
"upon which you  sat, and the man saw  reality and  nirvana for\n"+
"the first time."+
"\n"+
"                               - the musings of a philosopher \n"+
"\n"+
"                                 only those present during the\n"+
"                                 writing  of these  scriptures\n"+
"                                 will understand  these psalms\n");
}
