import { h } from 'preact';
import style from './style';

import Chat from '../chat';
import Participants from '../participants';

const RightBar = (props) => (
    <div class={style.right}>
        <Participants callback={props.callback} />
        <Chat typing={props.typing} text={props.text}/>
    </div>
);

export default RightBar;
