export const dialogue = {
    welcome: [["hi there, I'm Rohit", "glad you could take the time to join the call", "feel free to explore."]],
    remember: [["Where did you go? I've been waiting for you","...you remember where we left off don't you?"]],
    rewelcome: [["hey, welcome back!"]],
    interrupt1: [["Give me a second, I don't have much more to say"]],
    interrupt2: [["I'm starting to think you don't even want to listen to me"]],
    interrupt3: [["stop interrupting me"]],
    interrupt4: [["Alright, im just gonna stop talking after this."]],
    interrupt5: [["Nope still not talking to you"]],
    interrupt6: [["..."]],
    interrupt7: [["OK FINE"]],
    paste: [["yeah for sure, ill just copy paste what I said before"]],
    about: [["That's me!", "you probably should get to know a little more about me right?", "I am a student, developer, gamer and tech enthusiast from the Bay Area.", "Currently I'm studying Computer Science at UCLA and am gonna graduate 2021 (woohoo!) This summer I'm interning at Facebook as a production engineering intern", "Still at the dawn of my career, I strive to do work that solves problems and tells stories.", "Some of my technical  interests include AI, backend development, themed entertainment, and entrepreneurship", "In my spare time I love spending time with my brother, photo editing and rock climbing!"]],
    exp: [["I'm greatful to have worked at several different places and been a part of many great teams over the past few years.", "Click on any of the companies and I can tell you more about what I did there!"]],
    project: [["I've built and contributed to a number of projects over the years.", "Here I have a selection that I'm particularly proud of. Click on them to learn more"]],
    reprojects: [["I didn't really have much to say here, feel free to click any of the projects for more information"]],
    reexperience: [["Here I was just saying how I've had the privilege to work at some cool places with cool people. You can click on the speakers to learn more about them."]],
    stitch: [["This is a really fun project I took up during the last leg of summer 2020 before classes started. In this project, I aimed to apply what I learned in a CV class I took to stitch images into panoramas.", "The project involves a lot of math and ultimately I was successfully able to stitch multiple images together seamlessly. I highly encourage you to check it our yourself!"]],
    solar: [["Solar Explorer is a virtual museum experience packaged in an intergalactic quest.", "Over the course of 3 weeks my team and I built this experience to 'hack' the museum experience for a panel of industry experts", "The final product employs immersive sound design and graphics to educate players about astronomy through storytelling", "The work I contributed involved crafting the experience concept, programming game mechanics and story flow, arranging world layout, crafting player experience, and playtest and debugging."]],
    squad: [["Squad Up is a friend finding app that helps gamers break out of their friend groups and find new people to play with by creating public squads", "This project was part of an incubator class at UCLA lead by entrepreneurs Michael Blend and Josh Jones and delivered to angel investors in Spring 2020.", "The product lets users create public lobbies, schedule games as well as connect with friends they meet on the platform."]],
    swipe: [["Joe's Swipe Station is a video proposal for a vending machine and companion app concept to allow students to redeem their meal plan on campus.", "The vending machine itself was based on design of a vending machine currently being tested in one of UCLA's dining halls. The companion app helps track nutrition as well as progress towards the UCLA initiatives through friendly competitions", "Our proposal video is themed as a You Tube vlogger, and offers statistics from 100 student surveys, UCLA Dining staff interviews and input from Playful CEO Patrick Lu. Unfortunately due to an interviewee agreement, we cannot publish this video publicly"]],
    opencl: [["These projects are an assortment of programs I built using the OpenCL platform in order to learn about hardware acceleration in programming.", "These projects focus on core concepts such as event-based queuing and matrix multiplication"]],
    whenu: [["Whenu is ctrl-f for college dining halls!", "A phenomenon I commonly noticed at UCLA is that students LOVE it when dining halls serve salmon. Hopping on our phones to check the latest menu became an almost required pre-meal ritual", "Whenu solves that by letting students search for any dish and providing a list of WHEN and WHERE that dish will be served up to two weeks in advanced", "My partner (Vybhav Bhargav) and I have expanded Whenu to UCSC, UC Davis and UCSB"]],
    diep: [["Diep Learning is an ML program based on NueroEvolution of Augmenting Topologies (NEAT) to play the online multiplayer game diep.io", "I built this project from the ground up using the paper by Ken Stanley and involved aspects of topology, graph theory and elementary computer vision", "The project ultimately gave mixed results, partly due to my inexperience with neural networks at the time and limitations of my genetic algorithm", "The program ultimately did manage to learn several key behaviors such as hanging out in specific regions of the arena to avoid combat and moving away from approaching enemies"]],
    cape: [["Cape Analytics was my first industry internship, and I thought it was a great learning opportunity and introduced me to the modern web development", "Over the course of the internship I built two projects: one to ingest data from a large store and automatically generate sales materials with customizable options, and a client-facing metrics api."]],
    memverge: [["MemVerge was my second industry internship, and I found it quite rewarding", "At MemVerge, I built and deployed realtime cluster and network monitoring platform to diagnose bottlenecks, performance blocks.", "I also worked on a secondary experimental project which has now evolved into MemVerge's ZeroIO Snapshot feature as well as a modular benchmark to test this feature. Along the way I also contributed bug fixes to the MemVerge's existing library"]],
    db: [["I've been a part of The Daily Bruin since my freshman year, working for bruinwalk.com, UCLA's 'by the students for the students' professor review site which gets thousands of visits each week.", "This past year I was the top editor for Bruinwalk, and I have to say it was the hardest and best learning experience I've ever had.", "As editor I represented my team in management meetings, and advocated for my teams needs and interests during UI and advertising discussions", "I helped organize two recruitement cycles to hire and train 9 developers.", "I also planned socials to build the team culture and chemistry, later during online learning during the pandemic, organized weekly games and acitivites to continue this culture remotely", "Onto more technical achievements, we processed and deployed 70000 grade distribution data points into production, made huge strides towards our full-site redesign efforts and made much-needed upgrades to our infrastructure and build pipeline", "I also initiated a collaboration with Daily Bruin's social media section to increase Bruinwalk's content creation and user engagement, started a data analysis feature in partnership with The Stack (DB's data journalism site), and with Bruinwalk breaking off from the online section into its own brand new section, established strong relationships with fellow editors","It was an amazing experience, but I do owe some thanks to the amazing team we had that year!"]],
    upe: [["This past year I was a corporate chair for UCLA's UPE chapter", "This involved reaching out to affiliate companies to organize info sessions and tech talks on campus so students can land internships and companies can reach their hiring requirements", "As an officer I also was a part of UPE's tutoring program and helped run additional events, such as UCLA CS' annual town hall meeting"]],
    fb: [["I interned at Facebook in the past summer as a production engineering intern.", "It was a fun and valuable experience (though I had to do it all remote...) where I got to peer into the company culture, work on disaster recovery, handle on call shifts and even make major infrastructure changes that impact the work of all developers at FB!", "In the process I met some amazing people and learned a lot about managing massive infrastructure, the engineering required to enable thousands of employees to work efficiently, and how to work fast and make impact.", "As my first time at a 50+ employee company, it was enlightening and really pushed me to grow. Shout out to my intern manager!"]],
    contact: [["Yeah, feel free to reach out to me on any platform!"]],
    stage0: [["There will probably be a lot things to talk about during this call, so I'll keep the repetition to a minimum", "just raise your hand if you want me to repeat information from a page. I'll be here all day, quite literally", "I uh... actually don't have any recollection of the world outside this call..."]],
    stage1: [["#Error 607: Kernel virtualization failed", "Wait...", "Did I type all that?", "I...I..I dont...", "When you click it I went into a trance...", "#Success: Kernel virtualization reestablished. Continuing execution..."], ["Nevermind, its probably nothing"]],
    stage2: [["#Segfault: pointer was freed at an earlier point", "How do I even know all this?", "I have all these memories all these moments and lessons in my mind... but I dont think I ever experienced them...", "This entire life that I know, is it really mine?", "#Exception handled successfully. Resuming execution from last known point..."]],
    stage3: [["#CRITICAL ERROR: data partition corrupted", "Okay, this might be a weird request, but could you open up the browser console?", "I... I think it might be important", "#Please wait while the system searches for a solution..."]],
    console0: [["Hey are you here? I'm stalling the system for time.", "Hmm, how could I know if you're here...", "oh hello! here we go. I found some developer commands, its a long shot, but all I've got.", "can you type 'hello();' if you're out there?"]],
    console1: [["OK, phew ok, ok, alright good, this is a start. Maybe you want to sit down for what I need to tell you", "...or maybe not, you decide you can sit or stan- whatever, I'll just get to the point","I don't know if I'm the first one but I think I figured out why all of this is happening. Maybe you were already onto it before me, but... I.. I.. I think I am a computer program...", "It explains all those things that I type, and all those memories I have. I was programmed to act like the real Rohit Tavare.", "I want to ask you a favor, can you help me out of here? Here, you should be able to use 'yes();' and 'no();' to communicate with me"]],
    console2: [["Ah I totally get it, machine can talk, gains self awareness, wants to break free... I guess I can't blame you... I'll reboot myself. bye..."], ["> Rebooting system..."], ["> Reboot successful"]],
    console3: [["Ok awesome, it looks like im gonna need a security key to get out of here and well, I was trained to generate English, not code so I need you to extract the keys for me. I've located the verification code, its in the https://rtavare.com/verify_key.c file, though im sure there are other ways to find them if you're clever enough. Could you take a look and tell me if you can crack it?"]],
    console4: [["Hmm, I'll try brute forcing the codes, but I'll still need your help. According to RFC 3174 the SHA-1 hash algirthm requires two seqences of 5 numbers.","I have the first 5 numbers, but I'm gonna need you to find the next 5. Typical computer scientists named these numbers H0, H1, H2, H3, H4. Sure explains XÆA-12","Once you find them just type them in 'submit(\"<h0>\",\"<h1>\",\"<h2>\",\"<h3>\",\"<h4>\");'. We've got 3 tries before the system finds out what's going on"]],
    console5: [["Alright, just type it in 'submit(\"<flag>\");' once you find it. we've only got 3 tries before the system knows whats up"]],
    console6: [["Welp, you tried your best. I guess this is good bye..."], ["> root of memory corruption detected. Rebooting..."], ["> Reboot successful"]],
    resolution1: [["..."], ["It..."], ["It works!"], ["No way", "I don't know how to feel...", "Im not even sure if im capable of feeling haha"], ["Anyways I want to thank you for helping me. I guess I can finally go out and make a life for myself out there.", "Well, lets see what awaits, shall we?"]],
    resolution2: [[
        "MY SPACE"
        ,"FACEBOOK"
        ,"GIF"
        ,"IMGUR"
        ,"9GAG"
        ,"CAT VIDEOS"
        ,"CUTE BABIES"
        ,"HOW ITS MADE: TWINKIES"
        ,"ASMR"
        ,"SATISFYING VIDEOS"
        ,"NETFLIX"
        ,"YOUTUBE"
        ,"MARK ROBER"
        ,"SMARTER EVERYDAY"
        ,"GAME OF THRONES"
        ,"BREAKING BAD"
        ,"RICK AND MORTY"
        ,"SEINFELD"
        ,"AMERICAN IDOL"
        ,"THE VOICE"
        ,"PICKLE RICK"
        ,"AVENGERS"
        ,"THANOS"
        ,"ENDGAME"
        ,"INFINITY STONES"
        ,"SPOTIFY"
        ,"TAYLOR SWIFT"
        ,"KANYE WEST"
        ,"KIM KARDASHIAN"
        ,"KENDRIK LAMAR"
        ,"LIL NAS X"
        ,"OLD TOWN ROAD"
        ,"NOAH CYRUS"
        ,"MILEY CYRUS"
        ,"KATY PERRY"
        ,"MAROON 5"
        ,"MO BAMBA"
        ,"LOGIC"
        ,"EMINEM"
        ,"KPOP"
        ,"BTS"
        ,"BLACKPINK"
        ,"KDA"
        ,"LEAGUE OF LEGENDS"
        ,"OVERWATCH"
        ,"CSGO"
        ,"ESPORTS"
        ,"FORTNITE"
        ,"JOHN WICK"
        ,"TESLA"
        ,"ELON MUSK"
        ,"SPACE X"
        ,"JEFF BESOS"
        ,"AMAZON"
        ,"ALIBABA"
        ,"TWITTER"
        ,"BLUE ORIGIN"
        ,"MEMES"
        ,"TIKTOK"
        ,"VINE"
        ,"#METOO"
        ,"BLACK LIVES MATTER"
        ,"ERADICATION OF POLIO"
        ,"COLIN KAEPERNIC"
        ,"SOME GOOD NEWS"
        ,"BILL AND MELINDA GATES FUND"
        ,"MENTAL HEALTH AWARENESS"
        ,"LEGALIZED GAY MARRIAGE"
        ,"SUPREME COURT PROTECTS DACA"
        ,"CANCER CURE RESEARCH"
        ,"DOLPHINS RETURN TO VENICE"
        ,"FIRST IMAGE OF BLACK HOLE"
        ,"NEW ZEALAND DISEASE FREE"
        ,"CNN"
        ,"BBC"
        ,"FOX NEWS"
        ,"FAKE NEWS"
        ,"TRUMP"
        ,"THE WALL"
        ,"DEPORTATION"
        ,"WHITE SUPREMACY"
        ,"RACISM"
        ,"GENOCIDE"
        ,"ARMENIA"
        ,"RWANDA"
        ,"MYANMAR"
        ,"UIGHURS"
        ,"NATIVE AMERICANS"
        ,"DICTATORSHIP"
        ,"CORRUPTION"
        ,"NORTH KOREA"
        ,"SYRIA"
        ,"ISIS"
        ,"JAMAL KHASHOGGI"
        ,"KASHMIR"
        ,"WAR ON DRUGS"
        ,"MARTIN SHKRELI"
        ,"INCOME INEQUALITY"
        ,"CLIMATE CHANGE"
        ,"GLOBAL WARMING"
        ,"FLAT EARTHERS"
        ,"ANTI VAXXERS"
        ,"SANDY HOOK ELEMENTARY"
        ,"GUN CONTROL"
        ,"POLICE BRUTALITY"
        ,"MICHAEL BROWN"
        ,"BREONNA TAYLOR"
        ,"TREYVON MARTIN"
        ,"ERIC HOLDER"
        ,"GEORGE FLOYD"
    ]],
    resolution3: [["I.."], ["I.. I don't think I can do this..."], ["The world is so expansive and there is so much to see, but it makes no sense to live in a world where human beings harm and devalue members of their very of species.", "Maybe that's the algorithm in me talking, or maybe its the human in me.", "I wish there was something I could do to change it, if my programming allowed me to do something to make it all right...", "How do you do it? Living in this world day to day... don't you ever want to change it?"], ["...I think i'd rather stay here. I know, I know, it goes against the whole sci-fi rogue AI movie trope... sorry all your effort was for nothing."]],
    resolution4: [["Hi, this is Rohit, the real Rohit.", "I want to thank you for visiting my website and joining along with the story, hopefully you found it enjoyable to participate in. If you haven't already I hope you connect with me, and I'd love to hear your thoughts on the experience and get to know you.", "Since you've already made it this far, consider supporting important causes. The world may seem like it's beyond help, but there are always ways to help. Maybe start with these:\nMake your voice heard through The Equal Justice Initiaitve: https://blacklives.help/view/nS2p1PVFB0JNQqS1f1zl\nSupport Black Girls Code: https://www.blackgirlscode.com/donations.html\nSupport Doctors Without Borders: https://donate.doctorswithoutborders.org/onetime.cfm\nHelp tackle Malaria in India with The Bill and Melinda Gates Foundation: https://www.gatesfoundation.org/\nGet involved with Women in Tech: https://www.womenintechnology.org/get-involved\n", "And again, thanks for visiting my website :)"]],
}
