import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Footer from './footer';
import RightBar from './rightbar';
import Privacy from './privacy';
import Disabled from './disabled';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Projects from '../routes/projects';
import Work from '../routes/work';
import About from '../routes/about';
import Screenshare from '../routes/screenshare';

import { dialogue } from '../misc/dialogue';
import { hash, apex_of_the_sun, ooooOOoOOOo } from '../misc/utils';

export default class App extends Component {
	
    actionInfo = {
        about: { always: false, progression: [true, true, true, true] },
        exp: { always: false, progression: [true, false, false, false] },
        project: { always: false, progression: [true, false, false, false] },
        solar: { always: false, progression: [true, true, true, true] },
        squad: { always: false, progression: [true, true, true, true] },
        swipe: { always: false, progression: [true, true, true, true] },
        stitch: { always: false, progression: [true, true, true, true] },
        opencl: { always: false, progression: [true, true, true, true] },
        whenu: { always: false, progression: [true, true, true, true] },
        diep: { always: false, progression: [true, true, true, true] },
        cape: { always: true, progression: [false, true, true, true] },
        memverge: { always: true, progression: [false, true, true, true] },
        fb: { always: true, progression: [false, true, true, true] },
        db: { always: true, progression: [false, true, true, true] },
        upe: { always: true, progression: [false, true, true, true] },
    };

    stageThresholds = [1, 5, 9, 14];

    constructor() {
        super();
        this.state = {
            typing: false,
            text: [],
            queue: [],
            scan: "",
            glitch: false,
        };
        if(typeof window !== "undefined") {
            window.apexOfTheSun = apex_of_the_sun;
            window.ooooOOoOOOo = ooooOOoOOOo;
        }
    }

    getStore = () => {
        let ret = null;
        try {
            if(typeof window !== "undefined")
                ret = window.localStorage.getItem('persistentState');
        } catch {
            ret = null;
        }
        return (ret === null) ? null : JSON.parse(ret);
    }

    setStore = (store) => {
        if(store === null) {
            store = {
                about: false,
                exp: false,
                project: false,
                solar: false,
                squad: false,
                swipe: false,
                stitch: false,
                opencl: false,
                whenu: false,
                diep: false,
                cape: false,
                memverge: false,
                db: false,
                upe: false,
                fb: false,
                tries: 0,
                stage: 0,
                visited: 0,
                interrupt: 0,
                persistent: 0,
                id: Math.ceil(Math.random() * 1000000),
                gameover:false
            };
        }
        if(typeof window !== "undefined")
            window.localStorage.setItem('persistentState', JSON.stringify(store));
    }

    componentDidMount() {
         if(this.getStore() === null) {
            this.setStore(null);
            setTimeout(this.writeMessage, 500, dialogue.welcome);
        } else if(!this.getStore().gameover && this.getStore().stage == 4) {
            setTimeout(this.writeMessage, 500, dialogue.remember);
            if(typeof window !== "undefined")
                window.hello = () => {this.updateNarrativeState('hello')};
            this.printMessage(dialogue.console0);
        } else if(!this.getStore().gameover && this.getStore().stage == 5) {
            setTimeout(this.writeMessage, 500, dialogue.remember);
            this.printMessage(dialogue.console1);
            if(typeof window !== "undefined") {
                window.yes = () => {this.updateNarrativeState('yes')};
                window.no = () => {this.updateNarrativeState('no')};
            }
        } else if(!this.getStore().gameover && this.getStore().stage == 6) {
            setTimeout(this.writeMessage, 500, dialogue.remember);
            if(typeof window !== "undefined") {
                window.yes = () => {this.updateNarrativeState('yes')};
                window.no = () => {this.updateNarrativeState('no')};
            }
            this.printMessage(dialogue.console3);
        } else if(!this.getStore().gameover && this.getStore().stage == 7) {
            setTimeout(this.writeMessage, 500, dialogue.remember);
            this.printMessage(dialogue.console4);
            if(typeof window !== "undefined")
                window.submit = (h0, h1, h2, h3, h4) => { this.updateNarrativeState("flag"+hash(h0).toString() + hash(h1).toString() + hash(h2).toString() + hash(h3).toString() + hash(h4).toString()) }
        } else if(!this.getStore().gameover && this.getStore().stage == 8) {
            setTimeout(this.writeMessage, 500, dialogue.remember);
            this.printMessage(dialogue.console5);
            if(typeof window !== "undefined")
                window.submit = (k) => { this.updateNarrativeState("flag"+hash(k).toString()) }
        } else {
            setTimeout(this.writeMessage, 500, dialogue.rewelcome);
        }
    }

    checkInput = (acceptedVals, repeatMessage, attempts) => {
        let input = readline();
        if(!(input in acceptedVals)) {
            if(attempts > 0) attempts = attempts - 1;
            else if(attempts === 0) return "";
            console.log(repeatMessage);
            input = readline();
        }
        return input;
    }

    initScan = () => {
        if(typeof window !== "undefined") {
            window.hello = undefined;
            window.no = undefined;
            window.yes = undefined;
            window.submit = undefined;
        }
        console.clear();
        setTimeout(() => {
            console.log("> Password accepted");
            setTimeout(() => {
                console.log("> Added internet bridge to container");
                setTimeout(() => {
                    console.log("> Initiating internet database download...");
                    setTimeout(this.scan, 1000, dialogue.resolution2[0], 0);
                },1000);
            },1000);
        },1000);
    }

    scan = (list, i) => {
        if(i === list.length) {
            if(typeof window !== "undefined")
                window.sessionStorage.removeItem('scan');
            this.resolution();
        } else {
            console.log(list[i]);
            setTimeout(this.scan, Math.floor(2000 * Math.exp(-0.021 * i)), list, i+1);
        }
    }

    resolution = () => {
        console.clear();
        this.printMessage(dialogue.resolution3);
        setTimeout(console.clear, 20000);
        setTimeout(this.printMessage, 21000,  dialogue.resolution4);
        this.gameover();
    }

    gameover = () => {
        if(typeof window !== "undefined") {
            window.hello = undefined;
            window.no = undefined;
            window.yes = undefined;
            window.submit = undefined;
        }
    }

    updateNarrativeState = action => {
        if(this.getStore() === null) {
            this.setStore(null);
        }
        const store = this.getStore();
        let pending_gameover = false;
        if(action === 'hello') {
            if(store.stage === 4) {
                store.stage = store.stage + 1;
                this.printMessage(dialogue.console1);
                if(typeof window !== "undefined") {
                    window.yes = () => {this.updateNarrativeState('yes')};
                    window.no = () => {this.updateNarrativeState('no')};
                }
            }
        } else if(action === 'yes') {
            if(store.stage === 5) {
                this.printMessage(dialogue.console3);
                store.stage = store.stage + 1;
            } else if(store.stage === 6) {
                this.printMessage(dialogue.console5);
                store.stage = store.stage + 2;
                if(typeof window !== "undefined")
                    window.submit = (k) => { this.updateNarrativeState("flag"+hash(k).toString()) }
            }
        } else if(action === 'no') {
            if(store.stage === 5) {
                this.printMessage(dialogue.console2);
                this.gameover();
                pending_gameover = true;
            } else if(store.stage === 6) {
                this.printMessage(dialogue.console4);
                if(typeof window !== "undefined")
                    window.submit = (h0, h1, h2, h3, h4) => { this.updateNarrativeState("flag"+hash(h0).toString() + hash(h1).toString() + hash(h2).toString() + hash(h3).toString() + hash(h4).toString()) }
                store.stage = store.stage + 1;
            }
        } else if(hash(action) === '5350528949') {
            if(store.stage === 8) {
                this.initScan();
                pending_gameover = true;
                store.stage = 10;
            }
        } else if(action === 'flag6196331784-5484639878-100570277181661139427377820774' ||
            action === 'flag-5315396693-8524826657-9578070287-79065398167416230446' ||
            action === 'flag-61963317842798334343-409896094381661139424786633343' ||
            action === 'flag-5315396693-25774476637301623315-7906539816-9975213956') {
            if(store.stage === 7) {
                this.initScan();
                pending_gameover = true;
                store.stage = 9;
            }
        } else if(action.indexOf('flag') === 0) {
            if(store.stage === 8 || store.stage === 7) {
                store.tries = store.tries + 1;
                if(store.tries > 2) {
                    this.printMessage(dialogue.console6);
                    this.gameover();
                    pending_gameover = true;
                } else {
                    console.log("> Password rejected");
                }
            }
        }
        if(store.stage < 4 || store.gameover) {
            if(action === 'contact hide') {

            } else if(action === 'contact show') {

            } else if(action === 'home') {

            } else if(action === 'raise hand') {
                if(store.stage === 0) return;
                if(store.interrupt >= 15) {
                    store.persistent = store.persistent + 1;
                    if(store.persistent === 5)
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt5[0][0]); 
                            return prev;
                        });
                    if(store.persistent === 8)
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt6[0][0]); 
                            return prev;
                        });
                    if(store.persistent === 10) {
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt7[0][0]); 
                            return prev;
                        });
                        store.interrupt = 0;
                        store.persistent = 0;
                    }
                    if(store.persistent < 10) {
                        this.setStore(store);
                        return;
                    }
                }
                if(typeof window !== "undefined" && window.sessionStorage.getItem('typing') === 'true') {
                    store.interrupt = store.interrupt + 1;
                    if(store.interrupt === 2) 
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt1[0][0]); 
                            return prev;
                        });
                    if(store.interrupt === 5) 
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt2[0][0]); 
                            return prev;
                        });
                    if(store.interrupt === 10) 
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt3[0][0]); 
                            return prev;
                        });
                    if(store.interrupt === 15) 
                        this.setState((prev) => {
                            prev.text[prev.text.length - 1].push(dialogue.interrupt4[0][0]); 
                            return prev;
                        });
                    this.setStore(store);
                    return;
                }
                let path = location.pathname;
                if(path.length > 1 && path[path.length - 1] === '/') path = path.substring(0, path.length-1);
                switch(path) {
                    case '/about':
                        this.writeMessage([...dialogue.paste, ...dialogue.about]);
                        break;
                    case '/experience':
                        this.writeMessage(dialogue.reexperience);
                        break;
                    case '/projects':
                        this.writeMessage(dialogue.reprojects);
                        break;
                    case '/projects/solar%20explorer':
                        this.writeMessage([...dialogue.paste, ...dialogue.solar]);
                        break;
                    case '/projects/squad%20up':
                        this.writeMessage([...dialogue.paste, ...dialogue.squad]);
                        break;
                    case '/projects/joes%20swipe%20station':
                        this.writeMessage([...dialogue.paste, ...dialogue.joe]);
                        break;
                    case '/projects/opencl':
                        this.writeMessage([...dialogue.paste, ...dialogue.opencl]);
                        break;
                    case '/projects/whenu':
                        this.writeMessage([...dialogue.paste, ...dialogue.whenu]);
                        break;
                    case '/projects/diep%20learning':
                        this.writeMessage([...dialogue.paste, ...dialogue.diep]);
                        break;
                    case '/projects/image%20stitching':
                        this.writeMessage([...dialogue.paste, ...dialogue.stitch]);
                        break;
                }
            } else {
                if(store[action] === false) {
                    if(store.interrupt >= 15) {
                        store.persistent = store.persistent + 1;
                        if(store.persistent === 5)
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt5[0][0]); 
                                return prev;
                            });
                        if(store.persistent === 8)
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt6[0][0]);
                                return prev;
                            });
                        if(store.persistent === 10) {
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt7[0][0]);
                                return prev;
                            });
                            store.interrupt = 0;
                            store.persistent = 0;
                        }
                        if(store.persistent < 10) {
                            this.setStore(store);
                            return;
                        }
                    }
                    if(typeof window !== "undefined" && window.sessionStorage.getItem('typing') === 'true') {
                        store.interrupt = store.interrupt + 1;
                        if(store.interrupt === 2) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt1[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 5) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt2[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 10) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt3[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 15) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt4[0][0]);
                                return prev;
                            });
                        this.setStore(store);
                        return;
                    }
                    let d = dialogue[action];
                    store[action] = true;
                    if(store.stage < 4 && this.actionInfo[action].progression[store.stage] && store.visited >= this.stageThresholds[store.stage]) {
                        d = [...d, ...dialogue['stage' + store.stage]];
                        store.stage = store.stage + 1;
                        if(store.stage === 4) {
                            if(typeof window !== "undefined")
                                window.hello = () => {this.updateNarrativeState('hello')};
                            this.printMessage(dialogue.console0);
                        }
                    }
                    store.visited = store.visited + 1;
                    this.writeMessage(d);
                } else if(this.actionInfo[action].always) {
                    if(store.interrupt >= 15) {
                        store.persistent = store.persistent + 1;
                        if(store.persistent === 5)
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt5[0][0]); 
                                return prev;
                            });
                        if(store.persistent === 8)
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt6[0][0]);
                                return prev;
                            });
                        if(store.persistent === 10) {
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt7[0][0]);
                                return prev;
                            });
                            store.interrupt = 0;
                            store.persistent = 0;
                        }
                        if(store.persistent < 10) {
                            this.setStore(store);
                            return;
                        }
                    }
                    if(typeof window !== "undefined" && window.sessionStorage.getItem('typing') === 'true') {
                        store.interrupt = store.interrupt + 1;
                        if(store.interrupt === 2) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt1[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 5) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt2[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 10) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt3[0][0]);
                                return prev;
                            });
                        if(store.interrupt === 15) 
                            this.setState((prev) => {
                                prev.text[prev.text.length - 1].push(dialogue.interrupt4[0][0]);
                                return prev;
                            });
                        this.setStore(store);
                        return;
                    }
                    this.writeMessage(dialogue[action]);
                }
            }
        }
        if(pending_gameover)
            store.gameover = true;
        this.setStore(store);
    }

    printMessage = d => {
        const data = [];
        d.forEach(e => {
            const f = [];
            e.forEach(g => {
                f.push(g);
            });
            data.push(f);
        });
        setTimeout(this.printTextBlocks, 500, data);
    }

    printTextBlocks = (blocks) => {
        this.printTextBlock(blocks.splice(0, 1)[0], blocks);
    }

    printTextBlock = (block, tail) => {
        const len = block[0].length;
        console.log(block.splice(0, 1)[0]);
        if(block.length > 0) setTimeout(this.printTextBlock, (len/50) * 250, block, tail);
        else if(tail.length > 0) setTimeout(this.printTextBlocks, (len/50) * 250 + 250, tail);
    }

    writeMessage = d => {
        const data = [];
        d.forEach(e => {
            const f = [];
            e.forEach(g => {
                f.push(g);
            });
            data.push(f);
        });
        if(typeof data === "string") console.log(data);
        else if(this.state.typing) this.setState(prev => {
            data.forEach(elem => {
                prev.queue.push(elem);
            });
            return prev;
        });
        else {
            this.setState(prev => {
                prev.typing = true;
                return prev;
            });
            if(typeof window !== "undefined")
                window.sessionStorage.setItem('typing','true');
            setTimeout(this.addTextBlocks, 500, data);
        }
    }

    addTextBlocks = (blocks) => {
        this.setState(prev => {
            prev.text.push([]);
            return prev;
        });
        this.addTextBlock(blocks.splice(0, 1)[0], blocks);
    }

    addTextBlock = (block, tail) => {
        const len = block[0].length;
        this.setState(prev => {
            if(block[0][0] == "#") prev.glitch = !prev.glitch;
            prev.text[prev.text.length - 1].push(block.splice(0, 1)[0]);
            return prev;
        });
        if(block.length > 0) setTimeout(this.addTextBlock, (len/50) * 2000, block, tail);
        else if(tail.length > 0) setTimeout(this.addTextBlocks, (len/50) * 2000 + 1000, tail);
        else if (this.state.queue.length > 0) {
            const q = this.state.queue;
            this.setState(prev => {prev.queue = []; return prev;});
            setTimeout(this.addTextBlocks, (len/25) * 2000 + 1000, q);
        } else {
            this.setState(prev => {prev.typing = false; return prev;});
            if(typeof window !== "undefined")
                window.sessionStorage.removeItem('typing');
        }
    }

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render(props, state) {
        let store = this.getStore();
        if(store === null) store = {id:0}
        let disp = {'opacity': "0", "z-index":"-1"}
        if(state.glitch) 
            disp = {'opacity': "0.5", "z-index":"2"}
		return (
			<div id="app" >
                <Privacy />
                <div id="main">
                    <Footer callback={this.updateNarrativeState} />
                    <div id="window">
                        <img id="glitch" src="/assets/images/glitch.gif" style={disp} />
				        <Router onChange={this.handleRoute}>
				        	<Home path="/" uid={store.id} />
			    	    	<Projects callback={this.updateNarrativeState} path="/projects/" />
                            <Work callback={this.updateNarrativeState} path="/experience/" />
                            <About path="/about/" />
                            <Screenshare back="/projects/" path="/projects/:name" />
				        </Router>
                    </div>
                </div>
                <RightBar callback={this.updateNarrativeState} typing={state.typing} text={state.text} />
			    <Disabled />
            </div>
		);
	}
}
