import { h, Component } from 'preact';
import style from './style.css';

export default class Privacy extends Component {
    
    constructor() {
        super();
        this.state = { display: "none" };
    }

    showPriv = () => {
        this.setState(prev => {
            if(prev.display === "none") return {display: "flex"};
            else return {display: "none"};
        });
    }

    
    render(props, state) {
        return <div>
            <div onClick={this.showPriv} class={style.icon}>
                <p>i</p>
            </div>
            <div class={style.dropdown} style={state}>
                <h1>Data Policy</h1>
                <p class={style.text}>Despite the appearance of this website, I do not access your camera, microphone or send your data to a server. I do use localStorage which is only visible to you and you only.</p>
                <h1>Credits</h1>
                <p class={style.text}>dope headshot by <a href="https://www.vybhavb.com/" target="_blank">@vybhavb</a><br/>
                Navbar icons designed by Freepik and Those Icons<br/>
                Design inspiration from Zoom Inc.<br/><br/>This website is not affiliated or endorsed by Zoom Inc. in any way. The creator takes full responsibility of everything and anything this site says or does.</p>
                <p class={style.bottom} ><b>Made with ❤ by Rohit</b> © 2020</p>
            </div>
        </div>
    }
}
