import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css'

export default class Footer extends Component {

    constructor() {
        super();
        this.state = { display: "none" };
    }

    showEmotes = () => {
        this.setState(prev => {
            if(prev.display === "none") {
                return {display: "flex"};
            } else {
                return {display: "none"};
            }
        });
    }

    render(props, state) {
        return <footer class={style.footer}>
            <nav>
                <Link href="/about" class={style.button} onClick={() => {props.callback('about')}}><img src="/assets/images/user.png" /><p>About</p></Link>
                <Link href="/experience" class={style.button} onClick={() => {props.callback('exp')}}><img src="/assets/images/work.png" /><p>Experience</p></Link>
                <Link href="/" class={style.button} onClick={() => {props.callback('home')}}><img src="/assets/images/home-run.png" /><p>Home</p></Link>
                <Link href="/projects" class={style.button} onClick={() => {props.callback('project')}}><img src="/assets/images/rocket.png" /><p>Projects</p></Link>
                <div class={style.contact} >
                    <Link href="#" onClick={() => {props.callback((state.display === "none") ? "contact show" : "contact hide"); this.showEmotes();}} ><img src="/assets/images/emoji.png" /><p>Connect</p></Link>
                    <div class={style.dropdown} style={state} >
                        <a href="https://linkedin.com/in/rohittavare" target="_blank" onClick={() => {props.callback('linkedin')}}><img src="/assets/images/linkedin.png" /></a>
                        <a href="https://github.com/rohittavare" target="_blank" onClick={() => {props.callback('github')}}><img src="/assets/images/github1.png" /></a>
                        <a href="mailto:rohit.d.tavare@gmail.com" target="_blank" onClick={() => {props.callback('email')}}><img src="/assets/images/email.png" /></a>
                        <a href="https://unsplash.com/@rtavare" target="_blank" onClick={() => {props.callback('unsplash')}}><img src="/assets/images/photo.png" /></a>
                        <a href="https://www.artstation.com/rtavare" target="_blank" onClick={() => {props.callback('artstation')}}><img src="/assets/images/artstation.png" /></a>
                    </div>
                </div>
            </nav>
            <div class={style.leave}>
                <a href="/resume" target="_blank" >Resume</a>
            </div>
        </footer>;
    }
}

