import { h, Component } from 'preact';
import Match from 'preact-router/match';
import style from './style.css';

import Participant from '../participant';

export default class Participants extends Component {

    route_to_contents = {
        '/': {title: "Participants", items: [
            {initials:'RT', name: 'ROHIT TAVARE'},
            {initials:'G', name: 'GUEST'}
        ]},
        '/about': {title: "Skills/Languages", items: [
            {initials:'C', name: 'C/C++'},
            {initials:'PY', name: 'PYTHON'},
            {initials:'JS', name: 'JAVASCRIPT'},
            {initials:'D', name: 'DOCKER'},
            {initials:'PS', name: 'POSTGRESQL'},
            {initials:'L', name: 'LINUX'},
            {initials:'FB', name: 'FIREBASE'},
            {initials:'R', name: 'REDIS'},
            {initials:'PS', name: 'PHOTOSHOP'},
            {initials:'AI', name: 'ADOBE ILLUSTRATOR'},
            {initials:'A', name: 'AWS'},
            {initials:'P', name: 'PREACT'},
            {initials:'P', name: 'PROMETHEUS'},
            {initials:'G', name: 'GRAFANA'},
            {initials:'F', name: 'FIGMA'},
            {initials:'V', name: 'VUE'}
        ]},
        '/experience': {title: "Companies/Orgs", items: [
            {initials:'CA', name: 'CAPE ANALYTICS'},
            {initials:'M', name: 'MEMVERGE'},
            {initials:'DB', name: 'DAILY BRUIN'},
            {initials:'U', name: 'UPE UCLA'},
            {initials:'FB', name: 'FACEBOOK'}
        ]},
        '/projects': {title: "Projects", items: [
            {initials:'IS', name: 'IMAGE STITCHING', link:'https://colab.research.google.com/drive/13eqv-1AAKwkgXjaM82pqt8olgtaVyHpk?usp=sharing'},
            {initials:'SE', name: 'SOLAR EXPLORER', link:'https://solar-explorer.weebly.com/'}, 
            {initials:'SU', name:'SQUAD UP', link:'https://squad-up-21a9e.firebaseapp.com/home', github:'https://github.com/Kimeiga/squadup'}, 
            {initials:'J', name:'JOE\'S SWIPE SHACK'}, 
            {initials:'OC', name:'OPEN CL PROJECTS', github:'https://github.com/rohittavare/opencl-projects'}, 
            {initials:'W', name:'WHENU'}, 
            {initials:'DL', name:'DIEP LEARNING', link:'https://youtu.be/HLCRTR5g0QM', github: 'https://github.com/rohittavare/diep_learning'}
        ]},
        '/projects/solar%20explorer': {title: "Skills/Languages", items: [
            {initials:'U', name: 'UNITY'},
            {initials:'SD', name: 'STORY DESIGN'},
            {initials:'SD', name: 'SOUND DESIGN'},
            {initials:'WF', name: 'WAYFINDING'},
            {initials:'PX', name: 'PLAYER EXPERIENCE'},
            {initials:'ED', name: 'ENTERTAINMENT DESIGN'}
        ]},
        '/projects/squad%20up': {title: "Skills/Languages", items: [
            {initials:'V', name: 'VUE'},
            {initials:'VX', name: 'VUEX'},
            {initials:'S', name: 'SASS'},
            {initials:'F', name: 'FIGMA'},
            {initials:'FB', name: 'FIREBASE'},
            {initials:'P', name: 'PITCHING'}
        ]},
        '/projects/joes%20swipe%20station': {title: "Skills/Languages", items: [
            {initials:'F', name: 'FIGMA'},
            {initials:'I', name: 'INTERVIEWING'},
            {initials:'P', name: 'PLACEMAKING'},
            {initials:'T', name: 'THEMING'}
        ]},
        '/projects/opencl': {title: "Skills/Languages", items: [
            {initials:'C', name: 'C'},
            {initials:'OC', name: 'OPEN CL'},
            {initials:'HA', name: 'HARDWARE ACCELERATION'}
        ]},
        '/projects/whenu': {title: "Skills/Languages", items: [
            {initials:'FB', name: 'FIREBASE'},
            {initials:'BS', name: 'BEAUTIFUL SOUP'},
            {initials:'E', name: 'EXPRESS'},
            {initials:'R', name: 'REACT'},
            {initials:'PS', name: 'POSTRESQL'},
            {initials:'R', name: 'REDIS'},
            {initials:'H', name: 'HEROKU'},
            {initials:'A', name: 'AWS'}
        ]},
        '/projects/diep%20learning': {title: "Skills/Languages", items: [
            {initials:'PY', name: 'PYTHON'},
            {initials:'T', name: 'TESSERACT'},
            {initials:'W', name: 'WIN32'},
            {initials:'ML', name: 'MACHINE LEARNING'},
            {initials:'NN', name: 'NEURAL NETWORKS'},
            {initials:'GA', name: 'GENETIC ALGORITHM'}
        ]},
        '/projects/image%20stitching': {title: "Skills/Languages", items: [
            {initials:'PY', name: 'PYTHON'},
            {initials:'OC', name: 'OPEN CV 2'},
            {initials:'CV', name: 'COMPUTER VISION'},
            {initials:'LA', name: 'LINEAR ALGEBRA'},
        ]},
    };

    constructor() {
        super();
        this.state = {
            title: "Skills/Languages",
            items: [{initials:'JS', path: '', name:'JAVASCRIPT', github:'#'}, {initials:'C', name:'C', github:'#', link: '#'}],
            raised: false,
            filter: ''
        }
    }

    setPath = (path) => {
        this.setState(prev => {prev.path = path; return prev;});
    }

    setTitle = (title) => {
        this.setState(prev => {prev.title = title; return prev;});
    }

    setParticipants = (lst) => {
        this.setState(prev => {prev.items = lst; return prev;});
    }

    toggleHand = () => {
        this.setState((prev) => {
            prev.raised = !prev.raised;
            return prev;
        });
    }

    raiseHand = () => {
        this.setState((prev) => {
            prev.raised = true;
            return prev;
        });
    }

    lowerHand = () => {
        this.setState((prev) => {
            prev.raised = false;
            return prev;
        });
    }

    handleChange = (event) => {
        this.setState((prev) => {
            prev.filter = event.target.value;
            return prev;
        });
    }

    render(props, state) {
        let participants = [];
        state.items.forEach((e, i) => {
            if(state.filter.length > 0 && e.name.toLowerCase().indexOf(state.filter.toLowerCase()) === -1) return;
            if(e.link && e.github) participants.push(<Participant id={i} initials={e.initials} name={e.name} color={e.color} github={e.github} link={e.link} />);
            else if(e.link) participants.push(<Participant id={i} initials={e.initials} name={e.name} color={e.color} link={e.link} />);
            else if(e.github) participants.push(<Participant id={i} initials={e.initials} name={e.name} color={e.color} github={e.github} />);
            else participants.push(<Participant id={i} initials={e.initials} name={e.name} color={e.color} />);
        });
        let btn = [<button onClick={() => {props.callback('raise hand'); this.raiseHand(); setTimeout(this.lowerHand, 500);}} >Raise Hand</button>]
        if(state.raised) {
            btn = [<button onClick={() => {this.lowerHand();}} class={style.active} >Lower Hand</button>]
        }
        return <div class={style.participants}>
            <Match path='/'>
                {({matches, path, url}) => {
                        if(state.path != path) {
                            this.setPath(path);
                            if(path.length > 1 && path[path.length - 1] === '/') path = path.substring(0, path.length-1);
                            if(path in this.route_to_contents) {
                                this.setTitle(this.route_to_contents[path].title);
                                this.setParticipants(this.route_to_contents[path].items);
                            } else {
                                this.setTitle('Participants');
                                this.setParticipants([]);
                            }
                        }
                    }
                }
            </Match>
            <h3>{state.title} ({state.items.length})</h3>
            <input type="text" placeholder="🔍  Filter" value={this.state.filter} onInput={this.handleChange} ></input>
            <div class={style.list}>
                {participants}
            </div>
            <div class={style.buttons}>
                { btn }
            </div>
        </div>
    }
}
