import { h } from 'preact';
import style from './style.css';

const colors = ['#D35400', '#8E44AD', '#16A085', '#2980B9', '#F39C12']

const Participant = (props) => {
    let link = (props.link) ? <a href={props.link} target="_blank" ><img src="/assets/images/link.png" /></a> : <div></div> ;
    let github = (props.github) ? <a href={props.github} target="_blank" ><img src="/assets/images/logo.png" /></a> : <div></div> ;
    let col = { 'background-color': colors[props.id % colors.length]};
    return <div class={style.row}>
        <div class={style.profile} style={col}>
            {props.initials}
        </div>
        <p>{props.name}</p>
        <div class={style.git}>{github}</div>
        <div class={style.link}>{link}</div>
    </div>;
}

export default Participant;
