import { h, Component, Fragment, createRef } from 'preact';
import style from './style.css';

export default class Chat extends Component {

    ref = createRef();

    constructor() {
        super();
        this.state = {
            snap: true
        }
    }

    getSnapshotBeforeUpdate() {
        if(this.ref.current) {
            if(this.ref.current.scrollHeight - this.ref.current.getBoundingClientRect().height < this.ref.current.scrollTop + 5) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    render(props, state) {
        let text = [];
        props.text.forEach(e => {
            text.push(<p class={style.speaker}>From <span class={style.emph}>Rohit</span> to <span class={style.emph}>Everyone</span></p>);
            e.forEach(f => { 
                if(f.charAt(0) === '#') {
                    text.push(<p><b>{f.substring(1)}</b></p>)
                } else {
                     text.push(<p>{f}</p>);
                }
            });
        });
        let type = [];
        if(props.typing) type.push(<p class={style.typing}>Rohit is typing...</p>);
        return <div class={style.chat}>
            <h3>Group Chat</h3>
            <div class={style.textbox} ref={this.ref}>
                {text}
                {type}
            </div>
            <div class={style.disabled}>
                <p>Chat has been disabled for guests</p>
            </div>
        </div>
    }

    componentDidUpdate(prevP, prevS, snapshot) {
        if(snapshot)
            this.ref.current.scrollTop = this.ref.current.scrollHeight - this.ref.current.getBoundingClientRect().height;
    }
}
